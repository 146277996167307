<template>
  <div class="footer">
    <div class="footer-left"
      style="border:none;padding: 0px;display: flex;flex-direction:column;justify-content: center;align-items: center;">
      <!-- <img style="width: 2.8rem" src="../../../assets/foot-logo.png" alt="" /> -->
      <img style="width: 2.8rem" src="https://nmcmall.oss-cn-shenzhen.aliyuncs.com/site/logo/foot_logo.png" alt="" />
      <div style="font-size: 0.18rem;margin-top: 0.08rem;">
        <!-- <a target="_blank" href=" https://beian.miit.gov.cn/">Copyright © 萬博薈版權所有 2022</a> -->
        <a target="_blank" href=" https://beian.miit.gov.cn/">粤ICP备2022025767号</a>
      </div>
    </div>
    <div class="footer-center">
      <h2>聯繫電話 <span>Telephone</span></h2>
      <p>座機:(853)28752592</p>
      <p>傳真:(853)28752594</p>
    </div>
    <div class="footer-right" style="border-right: 2px solid #7b8ac4;padding-right: 1rem;" v-cloak>
      <h2>公司地址 <span>Address</span></h2>
      <p>澳門友誼大馬路中福商業中心14樓F-J</p>
      <p>珠海跨境工業園利時大廈6樓A5</p>
    </div>
    <div class="footer-right"
      style="border:none;padding: 0px;display: flex;flex-direction:column;justify-content: center;align-items: center;"
      v-cloak>
      <img style="width: 1.2rem;" src="../../../assets/found_page/qrcode.jpg" alt="">
      <p style="font-size:0.16rem">官方微信公衆號</p>
    </div>
  </div>
</template>

<script>
// import axios from "axios";
export default {
  name: "ConnectUs",
  data() {
    return {
      //这几个从vuex取
      hotPhone: "1234-567-890",
      beiAnHao: "澳門友誼大馬路中福商業中心14樓F-J",
      address: "珠海跨境工業園利時大廈6樓A5",
    };
  },
  mounted() {
    // axios.get("/hotLine").then((res) => {
    //   this.hotPhone = res.data;
    // });
    // axios.get("/beiAnNumber").then((res) => {
    //   this.beiAnHao = res.data;
    // });
    // axios.get("/address").then((res) => {
    //   this.address = res.data;
    // });
  },
};
</script>

<style lang="scss" scoped>
@import "@/common/style.scss";
@import "@/common/common.scss";

.footer {
  background-color: $themeColor_light;
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 2rem;
  padding: 0 1rem;
  width: 100%;
  box-sizing: border-box;

  >div {
    line-height: 2;
  }

  .footer-center,
  .footer-right {
    box-sizing: border-box;
    padding-left: 1rem;
    border-left: 2px solid #7b8ac4;

    h2 {
      font-size: 0.22rem;
    }

    span,
    p {
      font-size: 0.18rem;
      letter-spacing: 0.02rem;
    }
  }

  a {
    color: #fff;
    margin-bottom: 1rem;
  }

  h2 {
    font-size: 0.3rem;
  }

  p {
    font-size: 0.2rem;
  }
}
</style>