import request from './request'
// 获取二级菜单下的所有内容
export function getContentList(data) {
  return request({
    method: 'post',
    url: '/contentInfo',
    data
  })
}
// 获取具体内容信息
export function getContentById(id) {
  return request({
    method: 'get',
    url: '/contentInfo/' + id
  })
}