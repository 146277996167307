<template>
  <div class="block">
    <el-carousel :height="'7rem'" :indicator-position="'none'">
      <el-carousel-item v-for="(item, index) in bannerPictures" :key="index">
        <!-- <img :src="item.content" /> -->
        <img class="h100 w100" :src="require('@/assets/index_page/0505.jpg')" alt="" />
      </el-carousel-item>
      <el-row class="b-shadow-wrap">
        <el-col class="b-shadow" :span="10"></el-col>
      </el-row>
    </el-carousel>
    <el-row class="b-msg-box my_container">
      <el-col class="right" :xs="14" :sm="14" :md="14" :lg="14" :xl="14">
        <el-row :gutter="20" style="display: flex; justify-content: center;align-items: center; ">
          <div class="col pay_box" :style="showClass('pay')" @mouseenter="handleEnter('pay')"
            @mouseleave="handleLeave('pay')">
            <div class="hover_class" style="width:100%;height: 100%;">
              <div>
                <div class="my_title">支付全產業鏈</div>
                <div class="en_title">PAYMENT INDUSTRY CHAIN</div>
              </div>
              <ul class="my_style">
                <li class="com-desc-text ellipsis csp" v-if="mouseType == 'pay'" v-for="each in firstData"
                  :key="each.id" @click="detailNav('solvePages', each.id)">
                  {{ each.title }}
                </li>
                <div class="show-more csp dib" v-if="firstData.total > 3 && mouseType == 'pay'"
                  @click="pageNav('solvePages')">查看更多</div>
              </ul>
            </div>
          </div>
          <div class="col pay_box border_box" :style="showClass('border')" @mouseenter="handleEnter('border')"
            @mouseleave="handleLeave('border')">
            <div class="hover_class" style="width:100%;height: 100%;">
              <div class="my_title">
                跨境電商綜合服務
              </div>
              <div class="en_title">CROSS-BORDER E-COMMERCE INTEGRATED SERVICES</div>
              <ul class="my_style">
                <li v-for="each in secondData" :key="each.id" class="com-desc-text ellipsis csp"
                  v-if="mouseType == 'border'" @click="detailNav('crossBorder', each.id)">
                  {{ each.title }}
                </li>
                <div class="show-more csp dib" v-if="secondData.total > 3 && mouseType == 'border'"
                  @click="pageNav('crossBorder')">查看更多</div>
              </ul>
            </div>
          </div>
          <div class="col pay_box solve_box" :style="showClass('solve')" @mouseenter="handleEnter('solve')"
            @mouseleave="handleLeave('solve')">
            <div class="hover_class" style="width:100%;height: 100%;">
              <div class="my_title">
                數字化解決方案
              </div>
              <div class="en_title">DIGITAL SOLUTIONS</div>
              <ul class="my_style">
                <li v-for="each in thirdData" :key="each.id" class="com-desc-text ellipsis csp"
                  v-if="mouseType == 'solve'" @click="detailNav('digitalSolutions', each.id)">
                  {{ each.title }}
                </li>
                <div class="show-more csp dib" v-if="thirdData.total > 3 && mouseType == 'solve'"
                  @click="pageNav('digitalSolutions')">查看更多
                </div>
              </ul>
            </div>
          </div>
        </el-row>
      </el-col>
    </el-row>
    <!-- 文化理念 -->
    <!-- <section class="page-pdl my_container cultrue">
      <div class="cultrue-box">
        <div style="font-size: 0">
          <img
            style="width: 12.75rem"
            src="../../assets/index_page/index_page_cultrue.jpg"
            alt=""
          />
        </div>
        <div class="psa slogon y50">
          <ul class="pdt15 pdb15">
            <li>科技</li>
            <li>創新</li>
          </ul>
          <div class="psa r0 b0 slogon-text">INNOVATION</div>
        </div>
      </div>
    </section> -->
    <IndexTitle title="發展歷程" style="margin:90px auto 10px auto !important" enTitle="DEVELOPMENT HISTORY">
    </IndexTitle>
    <IndexReview style="margin:0 auto 60px auto !important"></IndexReview>
    <IndexTitle title="新聞動態" style="margin: 90px auto 60px auto !important" enTitle="NEWS TRENDS"></IndexTitle>
    <IndexNews class="ofh df jcc"></IndexNews>
    <!-- <IndexTitle title="聯繫我們" style="margin: 90px auto 60px auto !important" enTitle="CONTACT US"></IndexTitle>
    <div class="lh0 ofh" style="cursor: pointer;" @click="mapFn">
      <img style="" class="w100" src="../../assets/index_page/index_page_map.jpg" alt="" />
    </div> -->
    <!-- <GoodDesigner></GoodDesigner> -->
  </div>
</template>

<script>
import axios from "axios";
import IndexTitle from "./components/Index-title.vue";
import IndexReview from "./components/Index-review.vue";
import IndexNews from "./components/Index-news.vue";
// import GoodDesigner from '../indexPages/GoodDesigner'
import { getContentList } from "@/api/index";
export default {
  name: "FirstPage",
  data() {
    return {
      pageData: {
        pageNumber: 1,
        pageSize: 3
      },
      //以下是后端数据
      bannerPictures: [
        require("@/assets/index_page/index_page_banner.jpg"),
        "/img/banner3.jpg",
        "/img/banner4.jpg",
      ],
      firstData: [],
      secondData: [],
      thirdData: [],
      forthData: [],
      cip: '',
      coordinate: {},
      mouseType: ' '
    };
  },
  components: { IndexTitle, IndexReview, IndexNews },
  async mounted() {
    await this.getData(201)
    await this.getData(202)
    await this.getData(203)
    this.mouseType = ''
    // await this.getData(204)
    // this.cip = returnCitySN['cip']
    // console.log(this.cip);
    // // 根据IP获取坐标
    // axios.get('https://restapi.amap.com/v3/ip', { params: { key: 'e31590c991cadf5e13cc42d021e20573', ip: this.cip } })
    //   .then((res) => {
    //     console.log(res)
    //     if (res.data.status == 1) {
    //       // 获取到的经纬度处理
    //       this.coordinate.lat = parseFloat(res.result.location.lat).toFixed(6)
    //       this.coordinate.lng = parseFloat(res.result.location.lng).toFixed(6)
    //     }
    //   })
    //   .catch((e) => {
    //     console.log(e)
    //   })
  },
  computed: {
    showClass() {
      return function (type) {
        if (type === this.mouseType) {
          //移入 
          return "background-size: 100%;width:40%;transition-duration:1s;justify-content: center;";
        } else {
          if (this.mouseType == '') {
            return
          } else {
            return "background-size: cover;width:30%;transition-duration:1s";
          }
        }
      };
    },
  },
  methods: {
    // 鼠标移入
    handleEnter(type) {
      this.mouseType = type
    },
    // 鼠标移出
    handleLeave() {
      this.mouseType = ''
    },
    mapFn() {
      // window.location.href = 'https://map.baidu.com/poi/%E4%B8%AD%E7%A6%8F%E5%95%86%E4%B8%9A%E4%B8%AD%E5%BF%83/@12642229.996175794,2520902.8105856,13z?uid=98988bb5c4b2b3b83e9a5e51&ugc_type=3&ugc_ver=1&device_ratio=1&compat=1&pcevaname=pc4.1&querytype=detailConInfo&da_src=shareurl'
      // window.location.href = 'https://api.map.baidu.com/geocoder?location=22.212271,113.544789&output=html&src=webapp.baidu.openAPIdemo'
      window.location.href = 'https://api.map.baidu.com/geocoder?location=22.197706,113.565714&output=html&src=webapp.baidu.openAPIdemo'
    },
    pageNav(page) {
      this.$router.push({ path: `/solve/${page}` })
    },
    detailNav(page, id) {
      this.$router.push({ path: `/solve/${page}/${id}` })
    },
    async getData(id) {
      const res = await getContentList({
        id,
        ...this.pageData
      })
      let myData = res.data.records
      myData.total = res.data.total
      if (id == 201) {
        this.firstData = myData
      }
      if (id == 202) {
        this.secondData = myData
      }
      if (id == 203) {
        this.thirdData = myData
      }
      if (id == 204) {
        this.forthData = myData
      }
      // console.log(res);
    },
    handleCurrentChange(id) {
      // 新创标签并跳转打开
      const routeData = this.$router.resolve({
        name: "notice",
      });
      sessionStorage.setItem("url", "/noticeList/get?id=" + id);
      window.open(routeData.href, "_blank");
    },
  },
};
</script>

<style lang="scss" scoped>
.hover_class {
  // margin-right: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  &:hover {
    justify-content: center;
    background-color: rgba(5, 5, 5, 0.5);
  }
}

.pay_box {
  // padding-left: 20px;
  background: url("../../assets/index_page/pay.jpg") no-repeat top left;
  background-size: cover;
  width: 33%;
  height: 3.4rem;
  line-height: 1.5;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  // padding-bottom: 20px;
  transition-duration: 1s;
}

.border_box {
  background: url("../../assets/index_page/border.jpg") no-repeat top left;
  background-size: cover;
  margin-left: 20px;
  width: 33%;
}

.solve_box {
  background: url("../../assets/index_page/solve.jpg") no-repeat top left;
  background-size: cover;
  margin-left: 20px;
  width: 33%;
}

.static_class {
  background-size: cover;
  width: 20%;
}

.active_class {
  background-size: 100%;
  width: 60%;
}

.my_style {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-self: center;
  margin-left: 20px;

  li {
    color: #fff;
  }
}

.my_title {
  color: #fff;
  font-weight: 700;
  font-size: 24px;
  text-align: left;
  line-height: 2;
  margin-left: 20px;
}

.en_title {
  color: #fff;
  font-size: 14px;
  line-height: 1;
  margin-bottom: 20px;
  margin-left: 20px;
}

.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.firstTitle {
  font-size: 30px;
}

.secondTitle {
  font-size: 15px;
  color: grey;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}

#notice {
  min-height: calc(100vh - 381px);
  margin-left: 15%;
  margin-right: 15%;
  margin-top: 50px;
}

.notice-table {
  width: 49%;
  float: left;
  height: 360px;
}

.fenge {
  float: left;
  width: 1px;
  height: 450px;
  background-color: grey;
}
</style>
<style lang="scss" scoped>
@import "@/common/style.scss";
@import "@/common/common.scss";

// 首頁左右間距
.page-pdl {
  padding-left: 1.5rem;
}

.page-pdr {
  padding-right: 1.5rem;
}

.page-pd {
  padding: 1.5rem;
}

.block {
  width: 100%;
  overflow-x: hidden;

  .b-shadow-wrap {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 99;
  }

  .b-shadow {
    // background: rgba(100, 148, 168, 0.5);
    // height: 2.6rem;
    height: 2.75rem;
  }

  .b-msg-box {
    // height: 3.77rem;
    line-height: 2.5;

    &>div {
      height: 100%;
    }

    .right {
      width: 100%;
      padding-top: 0.5rem;

      li {
        font-size: 0.2rem;
        max-width: 3.5rem;
        transition-duration: 0s;

        &:hover {
          transition-duration: 0s;
          font-weight: 700;
          font-size: 0.25rem;
        }
      }
    }

    .show-more {
      font-size: 0.16rem;
      margin-top: 20px;
      color: #fff;
      text-align: left;

      &:hover {
        cursor: pointer;
        transition-duration: 0s;
        font-weight: 700;
        font-size: 0.25rem;
      }
    }
  }

  // 文化理念
  .cultrue {
    margin: 0.7rem auto 0.5rem auto;

    .cultrue-box {
      position: relative;
      display: inline-block;

      .slogon {
        width: 3.84rem;
        // height: 1rem;
        background: #3387c9;
        color: #fff;
        right: -2.84rem;
        font-size: 0.25rem;

        ul {
          margin-left: 0.35rem;

          li {
            // line-height: 1rem;
            letter-spacing: 0.2rem;
            padding: 2px 0;
          }
        }

        .slogon-text {
          font-size: 0.2rem;
          right: 0.12rem;
          bottom: 0.1rem;
        }
      }
    }
  }
}
</style>