<template>
  <div class="ofh psr">
    <!-- <div class="splitLine xy50"></div> -->
    <div style="text-align:center;color: #505050;font-size: 0.16rem;">左右滑動查看全部發展歷程</div>
    <!-- <div class="my_container review-wrap-box">
      <div v-for="(each, index) in 12" :key="index" class="review-wrap" :style="{
        left: reviewsList[index].left + 'rem',
        top: each % 2 === 0 ? '4.24rem' : '1.5rem',
      }">
        <img class="h100" style="width: 0.29rem" :style="{
          height: each % 2 === 0 ? '2.93rem' : '3.82rem',
          transform: `translateY(${each % 2 === 0 ? '-0.06rem' : '0.06rem'})`,
        }" :src="require(`@/assets/index_page/index_page_timeLamp0${each % 2 === 0 ? 1 : 2}.png`)" alt="" />
        <img :data-delay="index" :data-rate="0.5" class="calc-box psa" style="transform: translateX(0.15rem)" :style="{
          width: reviewsList[index].width + 'rem',
          bottom: each % 2 === 0 ? '' : 0.5 + 'rem',
          top: each % 2 === 0 ? 0.4 + 'rem' : '',
        }" :src="require(`../../../assets/index_page/review0${each}.png`)" alt="" />
      </div>
    </div> -->
    <div style="height:7.8rem;">
      <el-scrollbar style="height:100%;margin: 0 1rem;">
        <!-- <div v-for="(each, index) in 12" :key="index" style="width: 20%;display: inline-block;">
          <img style="width: 3rem;" :src="require(`../../../assets/index_page/review0${each}.png`)" alt="" />
        </div> -->
        <div style="width: 100%;display: inline-block;">
          <img style="width: 41rem;" :src="require(`../../../assets/index_page/lang_img.png`)" alt="" />
        </div>
      </el-scrollbar>
    </div>

  </div>
</template>

<script>
import animMixin from "@/mixins/animMixin.js";
export default {
  mixins: [animMixin],
  data() {
    this.reviewsList = [
      { width: 2, left: 0.2 },
      { width: 2, left: 0.7 },
      { width: 2, left: 2.8 },
      { width: 2, left: 3.4 },
      { width: 2, left: 5.4 },
      { width: 2, left: 6.1 },
      { width: 2, left: 8.06 },
      { width: 2, left: 8.7 },
      { width: 2, left: 10.7 },
      { width: 2, left: 11.25 },
      { width: 2, left: 13.35 },
      { width: 2, left: 13.9 },
    ];
    return {};
  },
  mounted() { },
};
</script>

<style lang="scss" scoped>
@import "@/common/style.scss";
@import "@/common/common.scss";

::v-deep .el-scrollbar .el-scrollbar__wrap .el-scrollbar__view {
  white-space: nowrap;
}

::v-deep el-scrollbar__bar is-vertical {
  display: none;
}

.splitLine {
  width: 100%;
  height: 0.04rem;
  background-image: linear-gradient(to right,
      #9f9fa0 0%,
      #9f9fa0 50%,
      transparent 50%);
  background-size: 0.3rem 0.02rem;
  background-repeat: repeat-x;
  margin: 0.15rem 0;
  position: relative;
}

.review-wrap-box {

  overflow: hidden;
  height: 100%;
  box-sizing: border-box;
  // margin-left: 1.4rem;
  // padding-left: 0.2rem;

  .review-wrap {
    display: inline-block;
    // float: left;
    position: absolute;

    // &:nth-child(odd) {
    //   // position: relative;
    //   // bottom: 2.2rem;
    // }

    .calc-box {
      z-index: 999;
    }
  }
}
</style>