import { render, staticRenderFns } from "./NoFound.vue?vue&type=template&id=eb40699e&scoped=true"
import script from "./NoFound.vue?vue&type=script&lang=js"
export * from "./NoFound.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "eb40699e",
  null
  
)

export default component.exports