<template>
  <div class="my_container tac title">
    <div class="first">{{ title }}</div>
    <br>
    <div class="second">{{ enTitle }}</div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "標題",
    },
    enTitle: {
      type: String,
      default: 'DEVELOPMENT HISTORY'
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/common/style.scss";
@import "@/common/common.scss";

.title {
  position: relative;
  margin: 0.55rem auto 0.35rem auto !important;

  .first {
    letter-spacing: 0.1rem;
    font-size: 0.42rem;
    color: $themeColor_light;
    font-weight: bold;
    margin-bottom: 0.1rem;
    display: inline-block;
    position: relative;

    &::before,
    &::after {
      content: "";
      display: inline-block;
      width: 0.06rem;
      height: 0.06rem;
      border-radius: 50%;
      background: $themeColor_light;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: -13%;
    }

    &::after {
      left: -13%;
    }

    &::before {
      right: -13%;
    }
  }

  .second {
    color: #505050;
    font-size: 0.22rem;
    display: inline-block;
    position: relative;
  }

  // span {
  //   &::before,::after {
  //     content: "";
  //     display: inline-block;
  //     width: 0.06rem;
  //     height: 0.06rem;
  //     border-radius: 50%;
  //     background: #000;
  //     position: absolute;
  //     top: 50%;
  //     left: 50%;
  //     transform: translate(-50%, -50%);
  //   }
  // }
}
</style>