<template>
  <div>
    <ul class="news-box">
      <li v-if="total > 8" class="right btn" @click="pageNav">
        查看更多>>>
      </li>
      <li class="item left box" style="width: 90%">
        <router-link class=" item" v-for="(ele, index) in data" :key="ele.id" tag="a" target="_blank"
          :to="`news/companyNews/${ele.id}?from=index`">
          <div>
            <img :src="ele.titlePic" alt="" />
          </div>
          <div class="bottom">
            <div v-if="ele.updateTime" class="time">{{ ele.updateTime }}</div>
            <div v-else class="time">{{ ele.createTime }}</div>
            <div class="title">{{ ele.title }}</div>
            <!-- <div class="time">2022年6月23日</div> -->
          </div>
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
import { getContentList } from "@/api/index";
export default {
  data() {
    this.newsList = [
      {
        title: "《澳門日報》",
        subtitle: "新大陸集團助澳經濟多元",
        desc: " 支付識別技術領域居世界領先 新大陸集團助澳經濟多元 ",
      },
      {
        title: "《福州晚報》 | 新大陸集團CEO 王晶：“數字新大陸”的拓荒者",
        desc: "福州晚報5月22日發表專題報導《王晶：“數字新大陸”的拓荒者》，學習強國平台轉發，澳門新大陸萬博官微今日全文刊發，以饗讀者。",
      },
      {
        title: "《澳門日報》",
        subtitle: "澳團：借鑒內地經驗發展電商",
        desc: "為期三日的二〇二二中國跨境電商交易會於6月3日結束，赴榕參觀的澳門代表團一行二十七人返回澳門。",
      },
      {
        title: " 倒計時10天 | 餐飲業後台電子 化資助計劃申請最後召集！",
        desc: "【澳門餐飲業後台電子化資助計劃】 申請最後倒計時10天！ 6月17日申請截止！！",
      },
    ];
    return {
      total: null,
      pageData: {
        pageNumber: 1,
        pageSize: 8
      },
      data: []
    }
  },
  async created() {
    this.getData()
  },
  methods: {
    pageNav() {
      this.$router.push({ path: '/news/companyNews' })
    },
    async getData() {
      const res = await getContentList({
        id: 301,
        ...this.pageData
      })
      this.data = res.data.records
      this.total = res.data.total
      console.log(res);
    },
  }
};
</script>

<style lang="scss" scoped>
@import "@/common/style.scss";
@import "@/common/common.scss";

.btn {
  display: flex;
  justify-content: right;
  align-items: center;
  margin: 0 1rem 0.1rem 1rem;
  color: #777;
  cursor: pointer;

  &:hover {
    color: #00479d;
  }
}

.box {
  display: flex;
  justify-content: left;
  flex-wrap: wrap;
  padding: 0 1rem 30px 1rem;

  .item {
    width: 25%;
    margin-bottom: 0.3rem;
    // font-size: 12px;
    background-color: #f8f8f8;

    &:nth-child(2n) {
      background: #f8f8f8;
      color: black;

      &:hover {
        color: #fff;
        background: #00479d;
      }
    }

    &:nth-child(2n-1) {
      background: #e9f1fb;
      color: black;

      &:hover {
        color: #fff;
        background: #00479d;
      }
    }

    img {
      width: 100%;
      // height: 2rem;
      object-fit: fill;
    }
  }
}

.news-box {
  .item {
    .bottom {
      position: relative;
      padding: 20px 0.3rem 0.1rem 0.3rem;

      .title {
        font-weight: normal;
        line-height: 0.4rem;
        font-size: 0.2rem;
        padding-top: 40px;

        .news-title {
          text-indent: 1em;
        }
      }

      .news-desc {
        line-height: 0.4rem;
        color: #908f8c;
        font-size: 0.16rem;
        font-weight: bold;
        padding-top: 0rem;
        letter-spacing: 1px;
        @include more-ellipsis(3);

        &:hover {
          color: $themeColor_light;
          cursor: pointer;
        }
      }
    }
  }
}
</style>