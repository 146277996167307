const animMixin = {
    data() {
        return {
            // 動畫配置
            aniOptions: {
                delay: 1000,
                afterReset: function (el) {
                    //离开视图时重置，移除动画类名
                    el.classList.remove("animated");
                    el.classList.remove("bounceInLeft");
                },
                beforeReveal: function (el) {
                    // 到达视图时触发，增加动画类名，添加执行动画时常
                    el.classList.add("animated");
                    el.classList.add("bounceInLeft");
                    // console.log(el.dataset['delay'])
                    if (el.dataset['delay']) {
                        el.style['animation-delay'] = Number(el.dataset['delay']) * (el.dataset['rate'] || 0.2) + 's';
                        el.style['-webkit-animation-delay'] = el.dataset['delay'] * (el.dataset['rate'] || 0.2) + 's';
                    }
                },
            },
        }
    }
}
export default animMixin