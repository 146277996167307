import VueRouter from 'vue-router'

import IndexPage from '@/views/indexPages/IndexPage';
import FirstPage from '@/views/indexPages/FirstPage';
// import AnLi from '@/views/indexPages/AnLi';
import GongLue from '@/views/indexPages/GongLue';
import GoodDesigner from '@/views/indexPages/GoodDesigner';
// import ConnectUs from '@/views/indexPages/ConnectUs';
import GoodMall from '@/views/indexPages/GoodMall';
import ZhaoPin from '@/views/indexPages/ZhaoPin';
import IntroduceMe from '@/views/indexPages/IntroduceMe';
import NoticePage from '@/views/indexPages/NoticePage';
import GoodDetails from '@/views/indexPages/GoodDetails';
import AnLiDetail from '@/views/indexPages/AnLiDetail';
import NoFound from '@/views/NoFound';
// 嬾加載
/**
 * 關於万博
 */
const About = () => import('@/views/aboutPages');
const CompanyCultrue = () => import('@/views/aboutPages/companyCultrue');
const CompanyDetail = () => import('@/views/aboutPages/companyDetail');
/**
 * 解决方案
 */
const Solve = () => import('@/views/solvePages');
const SolvePages = () => import('@/views/solvePages/solvePages');
const CrossBorder = () => import('@/views/solvePages/crossBorder');
const DigitalSolutions = () => import('@/views/solvePages/digitalSolutions');
const ProfessionalService = () => import('@/views/solvePages/professionalService');
const ServiceDetails = () => import('@/views/solvePages/serviceDetails');
const DigitalDetails = () => import('@/views/solvePages/digitalDetails');
const CrossDetails = () => import('@/views/solvePages/crossDetails');
const PagesDetails = () => import('@/views/solvePages/pagesDetails');
/**
 * 新聞動態
 */
const News = () => import('@/views/newsPages');
const CompanyNews = () => import('@/views/newsPages/companyNews');
const CultrueActivity = () => import('@/views/newsPages/cultrueActivity');
const ActivityDetails = () => import('@/views/newsPages/activityDetails');
const NewsDetails = () => import('@/views/newsPages/newsDetails');
/**
 * 探索萬博
 */
const ExplorePages = () => import('@/views/explorePages')
const YouthTheory = () => import('@/views/explorePages/youthTheory')
const IpSurrounding = () => import('@/views/explorePages/ipSurrounding')
const YouthTheoryDetails = () => import('@/views/explorePages/youthTheoryDetails')
const IpSurroundingDetails = () => import('@/views/explorePages/ipSurroundingDetails')
/**
 * 發現萬博
 */
const Found = () => import('@/views/foundPages');
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}
const router = new VueRouter({
    mode: "hash",
    routes: [
        {
            path: '/',
            component: IndexPage,
            meta: {
                title: '澳門新大陸萬博科技有限公司'
            },
            children: [
                {
                    name: 'index',
                    path: '', //不用加斜杠
                    component: FirstPage,
                    meta: {
                        title: '澳門新大陸萬博科技有限公司'
                    },
                },
                {
                    name: 'about',
                    path: 'about',
                    component: About,
                    children: [
                        {
                            name: 'companyCultrue',
                            path: 'companyCultrue',
                            meta: {
                                title: '公司文化'
                            },
                            component: CompanyCultrue
                        },
                        {
                            name: 'companyDetail',
                            path: 'companyDetail',
                            meta: {
                                title: '公司簡介'
                            },
                            component: CompanyDetail
                        },
                    ],
                    meta: {
                        title: '關於萬博'
                    },
                },
                {
                    name: 'solve',
                    path: 'solve',
                    component: Solve,
                    redirect: '/solve/solvePages',
                    children: [
                        {
                            name: 'solvePages',
                            path: 'solvePages',
                            meta: {
                                title: '支付全產業鏈'
                            },
                            component: SolvePages
                        },
                        {
                            name: 'companyNewsId',
                            path: 'solvePages/:id',
                            meta: {
                                title: '支付全產業鏈'
                            },
                            component: PagesDetails
                        },
                        {
                            name: 'crossBorder',
                            path: 'crossBorder',
                            meta: {
                                title: '跨境電商綜合服務'
                            },
                            component: CrossBorder
                        },
                        {
                            name: 'companyNewsId',
                            path: 'crossBorder/:id',
                            meta: {
                                title: '跨境電商綜合服務'
                            },
                            component: CrossDetails
                        },
                        {
                            name: 'digitalSolutions',
                            path: 'digitalSolutions',
                            meta: {
                                title: '數字化解決方案'
                            },
                            component: DigitalSolutions
                        },
                        {
                            name: 'companyNewsId',
                            path: 'digitalSolutions/:id',
                            meta: {
                                title: '數字化解決方案'
                            },
                            component: DigitalDetails
                        },
                        {
                            name: 'professionalService',
                            path: 'professionalService',
                            meta: {
                                title: '專業化服務團隊'
                            },
                            component: ProfessionalService
                        },
                        {
                            name: 'companyNewsId',
                            path: 'professionalService/:id',
                            meta: {
                                title: '專業化服務團隊'
                            },
                            component: ServiceDetails
                        },
                    ],
                    meta: {
                        title: '新聞動態'
                    },
                },
                {
                    name: 'news',
                    path: 'news',
                    component: News,
                    redirect: '/news/companyNews',
                    children: [
                        {
                            name: 'companyNews',
                            path: 'companyNews',
                            meta: {
                                title: '企業新聞'
                            },
                            component: CompanyNews
                        },
                        {
                            name: 'companyNewsId',
                            path: 'companyNews/:id',
                            meta: {
                                title: '新聞'
                            },
                            component: NewsDetails
                        },

                    ],
                    meta: {
                        title: '新聞動態'
                    },
                },
                {
                    name: 'explorePages',
                    path: 'explorePages',
                    component: ExplorePages,
                    redirect: '/explorePages/youthTheory',
                    children: [
                        {
                            name: 'youthTheory',
                            path: 'youthTheory',
                            meta: {
                                title: '萬博青年說',
                                id: 401,
                            },
                            component: YouthTheory
                        }, {
                            name: 'ipSurrounding',
                            path: 'ipSurrounding',
                            meta: {
                                title: '萬博IP及周邊',
                                id: 402,
                            },
                            component: IpSurrounding
                        },
                        {
                            name: 'companyNewsId',
                            path: 'youthTheory/:id',
                            meta: {
                                title: '萬博青年說'
                            },
                            component: YouthTheoryDetails
                        },
                        {
                            name: 'companyNewsId',
                            path: 'ipSurrounding/:id',
                            meta: {
                                title: '萬博IP及周邊'
                            },
                            component: IpSurroundingDetails
                        },
                        {
                            name: 'cultrueActivity',
                            path: 'cultrueActivity',
                            meta: {
                                title: '文化活動'
                            },
                            component: CultrueActivity
                        },
                        {
                            name: 'cultrueActivityId',
                            path: 'cultrueActivity/:id',
                            meta: {
                                title: '文化活動'
                            },
                            component: ActivityDetails
                        }
                    ],
                    meta: {
                        title: '探索萬博'
                    },
                },
                {
                    name: 'found',
                    path: 'found',
                    component: Found,
                    meta: {
                        title: '發現萬博'
                    },
                },
                {
                    name: 'designer',
                    path: 'designer',
                    component: GoodDesigner,
                    meta: {
                        title: 'Z家装-设计师'
                    },
                },
                // {
                //     name:'connectus',
                //     path:'connectus',
                //     component:ConnectUs,
                //     meta:{
                //         title: 'Z家装-联系我们'
                //     },
                // },
                {
                    name: 'goodmall',
                    path: 'goodmall',
                    component: GoodMall,
                    meta: {
                        title: 'Z家装-产品'
                    }
                },
                {
                    name: 'zhaopin',
                    path: 'zhaopin',
                    component: ZhaoPin,
                    meta: {
                        title: 'Z家装-招聘信息'
                    }
                },
                {
                    name: 'introduce',
                    path: 'introduce',
                    component: IntroduceMe,
                    meta: {
                        title: 'Z家装-公司介绍'
                    }
                },
                {
                    name: 'notice',
                    path: 'notice',
                    component: NoticePage,
                    //props:true,
                    meta: {
                        title: 'Z家装-详情'
                    }
                },
                {
                    name: 'gooddetail',
                    path: 'gooddetail',
                    component: GoodDetails,
                    meta: {
                        title: 'Z家装-产品详情'
                    }
                },
                {
                    name: 'anlidetail',
                    path: 'anlidetail',
                    component: AnLiDetail,
                    meta: {
                        title: 'Z家装-案例详情'
                    }
                },


            ]
        },
        {
            path: '/404',
            name: '404',
            component: NoFound,
            meta: {
                title: 'Z家装-404'
            },
        },
        // {
        //     path: '*',
        //     redirect: '/404'
        // }
    ]
})

//登录拦截
router.beforeEach((to, from, next) => {
    if (to.meta.requireAuth) {  // 判断该路由是否需要登录权限
        //如果需要就执行下面的代码

        var isLogin = sessionStorage.getItem("isLogin")
        console.log(isLogin)
        // 通过sessionStorage 获取当前的isLogin的值 将我们保存的isLogin的值赋给num,num是顺便取的名称，可以换
        //我们在登录界面，我们使用请求，请求成功后，我们就使用sessionStorage为‘isLogin’保存一个值  1，如果请求失败，就不保存‘isLogin’的值
        //如果请求成功，num的值就是1，请求失败就是null，所以下面进行判断
        if (isLogin) {
            //如果登录了，就跳转到'/index'路径
            //next({path:'/admin'});
            next();
        }
        else {
            next({
                path: '/login',//返回登录界面
                // query: {redirect: to.fullPath}  
            })
        }
    }
    else {
        //如果不需要登录权限就直接跳转到该路由
        next();
    }
})
export default router;