<template>
  <div id="main">
    <el-backtop :bottom="60">
      <div
        style="
          height: 100%;
          width: 100%;
          background-color: #f2f5f6;
          box-shadow: 0 0 6px rgba(0, 0, 0, 0.12);
          text-align: center;
          line-height: 40px;
          color: #1989fa;
        "
      >
        <i class="el-icon-top"></i>
      </div>
    </el-backtop>
    <!-- 客服浮窗 -->
    <transition name="slideFade">
      <div v-show="phoneShow" class="phone_bar">
        <div class="kefu-dialog">
          <div class="title" style="padding: 5px">
            <img
              class=""
              src="../../assets/phone_icon.png"
              style="width: 10px; margin-right: 5px"
            />
            <span style="font-weight: 600">客服電話</span>
            <span style="color: #a5a5a5">（週一至週五）</span>
          </div>
          <div class="title" @click="navToKefu">
            <span>大陸電話：</span>
            <span style="color: #00479d">（+86）19168690115</span>
          </div>
          <div class="title">
            <span>澳門電話：</span>
            <span style="color: #00479d">（+853）68690115</span>
          </div>
        </div>
      </div>
    </transition>

    <div class="kefu-bar">
      <img
        class=""
        src="../../assets/kefu_icon.png"
        style="width: 200px; margin-left: -40px"
      />
      <div class="btn">
        <div class="box" @click="navToKefu">聯繫客服</div>
        <div class="line"></div>
        <div class="box2" @click="phoneShow = !phoneShow">電話諮詢</div>
      </div>
    </div>
    <div>
      <div class="top-navbar">
        <el-menu
          :default-active="activeIndex"
          class="el-menu-demo my_container"
          mode="horizontal"
          text-color="#959391"
          active-text-color="#00479d"
          :router="true"
        >
          <!-- <el-menu-item class="hoverItem logoItem"><img class="h100" :src="require('@/assets/logo.png')" /> -->
          <el-menu-item class="hoverItem logoItem"
            ><img
              class="h100"
              src="https://nmcmall.oss-cn-shenzhen.aliyuncs.com/site/logo/header_logo.png"
            />
          </el-menu-item>
          <el-menu-item class="hoverItem indexItem" index="/"
            >首頁</el-menu-item
          >
          <el-submenu
            :index="each.index"
            v-for="each in navList"
            :key="each.index"
            class="submenu-parent"
          >
            <template slot="title">{{ each.title }}</template>
            <el-menu-item
              :index="child.index"
              v-for="child in each.children"
              :key="child.index"
              class="submenu"
            >
              <span>{{ child.title }}</span>
            </el-menu-item>
          </el-submenu>
        </el-menu>
      </div>
      <el-main>
        <router-view></router-view>
      </el-main>
      <Footer></Footer>
    </div>
  </div>
</template>

<script>
// import axios from "axios";
import Footer from "./components/Footer.vue";
const navList = [
  // {
  //   title: "首页",
  //   index: "0",
  // },
  {
    title: "關於萬博",
    index: "1",
    children: [
      {
        title: "公司簡介",
        id: 101,
        index: "/about/companyDetail?ahref=detail",
      },
      {
        title: "企業文化",
        id: 102,
        index: "/about/companyDetail?ahref=cultrue",
      },
    ],
  },
  {
    title: "解決方案",
    index: "2",
    children: [
      {
        title: "支付全產業鏈",
        index: "/solve/solvePages",
        id: 201,
      },
      {
        title: "跨境電商綜合服務",
        index: "/solve/crossBorder",
        id: 202,
      },
      {
        title: "數字化解決方案",
        id: 203,
        index: "/solve/digitalSolutions",
      },
      // {
      //   title: "專業化服務團隊",
      //   id: 204,
      //   index: "/solve/professionalService",
      // },
    ],
  },
  {
    title: "新聞動態",
    index: "3",
    children: [
      {
        title: "企業新聞",
        id: 301,
        index: "/news/companyNews",
      },
      // {
      //   title: "文化活動",
      //   id: 302,
      //   index: "/news/cultrueActivity",
      // },
    ],
  },
  {
    title: "探索萬博",
    index: "4",
    children: [
      {
        title: "萬博青年説",
        id: 401,
        index: "/explorePages/youthTheory",
      },
      {
        title: "萬博IP及周邊",
        id: 402,
        index: "/explorePages/ipSurrounding",
      },
      {
        title: "文化活動",
        id: 302,
        index: "/explorePages/cultrueActivity",
      },
    ],
  },
  {
    title: "發現萬博",
    index: "/found",
    children: [
      {
        title: "合作夥伴",
        index: "/found?ahref=partner",
        id: 501,
      },
      {
        title: "聯繫我們",
        id: 502,
        index: "/found?ahref=contact",
      },
      {
        title: "加入我們",
        id: 503,
        index: "/found?ahref=join",
      },
    ],
  },
];
export default {
  name: "IndexPage",
  components: {
    Footer,
  },
  data() {
    return {
      activeIndex: "1",
      //以下为后端数据
      date: "日期",
      hotPhone: "",
      beiAnHao: "",
      address: "",
      phoneShow: false,
    };
  },
  computed: {
    navList() {
      return navList;
    },
  },
  mounted() {
    // axios.get("/hotLine").then((res) => {
    //   this.hotPhone = res.data;
    // });
    // axios.get("/beiAnNumber").then((res) => {
    //   this.beiAnHao = res.data;
    // });
    // axios.get("/address").then((res) => {
    //   this.address = res.data;
    // });
  },
  methods: {
    dateFormat() {
      var date = new Date();
      var year = date.getFullYear();
      var month =
        date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1;
      var day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
      // var hours = date.getHours() < 10 ? '0' + date.getHours() : date.getHours()
      // var minutes = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()
      // var seconds = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
      return year + "年 " + month + "月 " + day + "日 ";
    },
    navToKefu() {
      this.phoneShow = false;
      // window.location.href = 'https://cs.newlandmaanbok.com/h5?website_token=7op9kYocYBfsvAq5XGxeHvpT'
      window.open(
        "https://cs.newlandmaanbok.com/h5?website_token=7op9kYocYBfsvAq5XGxeHvpT",
        "_blank"
      );
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/common/style.scss";
@import "@/common/common.scss";

.slideFade-enter {
  animation: slideFade-dialog-fade-in 0.5s ease;
}

.slideFade-leave {
  animation: slideFade-dialog-fade-out 0.5s ease forwards;
}

.slideFade-enter-active {
  animation: slideFade-dialog-fade-in 0.5s ease;
}

.slideFade-leave-active {
  animation: slideFade-dialog-fade-out 0.5s ease forwards;
}

@keyframes slideFade-dialog-fade-in {
  0% {
    transform: translate3d(
      97%,
      0,
      0
    ); //修改这个可以控制，上下左右动画，例如：100%为从右到左
    opacity: 0;
  }

  100% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

@keyframes slideFade-dialog-fade-out {
  0% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }

  100% {
    transform: translate3d(97%, 0, 0);
    opacity: 0;
  }
}

.phone_bar {
  width: 100px;
  position: fixed;
  bottom: 20%;
  right: 3%;
  height: 235px;
  z-index: 999;

  .kefu-dialog {
    margin-right: 180px;
    position: absolute;
    bottom: 2%;
    right: 0%;
    width: 250px;
    height: 100px;
    background: #fff;
    border-radius: 6px;
    padding: 20px 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.3);

    .title {
      padding: 5px 5px 5px 22px;

      span {
        font-size: 14px;
        color: #2e3845;
      }
    }
  }
}

.kefu-bar {
  position: fixed;
  bottom: 20%;
  right: 3%;
  z-index: 999;

  .line {
    width: 55%;
    height: 1px;
    background: #d8d8d8;
  }

  .btn {
    width: 130px;
    height: 150px;
    font-size: 18px;
    color: #00479d;
    border-radius: 10px;
    background: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.3);
    margin-top: -30px;
    position: sticky;
    z-index: 1000;

    .box {
      width: 100%;
      height: 50%;
      display: flex;
      justify-content: center;
      align-items: center;

      &:hover {
        background: #00479d;
        color: #fff;
        border-radius: 10px 10px 0 0;
      }
    }

    .box2 {
      width: 100%;
      height: 50%;
      display: flex;
      justify-content: center;
      align-items: center;

      &:hover {
        background: #00479d;
        color: #fff;
        border-radius: 0 0 10px 10px;
      }
    }
  }

  &:hover {
    cursor: pointer;

    .line {
      width: 55%;
      height: 0px;
      background: #fff;
    }
  }
}

.el-main {
  padding: 0;
  overflow: inherit;
}

.el-main {
  /*background-color: #e9eef3;*/
  color: #333;
}

.top-navbar {
  background: #fff;
  position: sticky;
  top: 0;
  z-index: 9999;
}

.logoItem {
  cursor: auto !important;
  margin-right: 2.2rem !important;
  margin-left: -0.22rem !important;
  padding: 0;
}

.indexItem {
  margin-right: 0.7rem !important;
  padding: 0;
}

.hoverItem {
  font-size: 14px;

  &:hover {
    font-weight: normal;
    color: #00479d;
    background-color: #fff !important;
  }
}

.hoverItem:hover {
  color: #00479d;
  font-weight: normal;
  background-color: #fff !important;
}

::v-deep .el-submenu {
  float: left;
}

::v-deep.el-menu--horizontal > .el-menu > .el-menu-item.submenu > span {
  font-weight: normal !important;
  color: #00479d !important;

  &:hover {
    font-weight: normal !important;
    color: #00479d !important;
  }
}

::v-deep .el-menu-item,
::v-deep .el-submenu__title {
  padding: 0;
  color: #777 !important;

  &:hover {
    font-weight: normal !important;
    color: #00479d !important;
  }

  // margin-right: 0.7rem !important;
}

// menu下劃綫
::v-deep .el-menu--horizontal > .el-submenu.is-active .el-submenu__title {
  font-weight: 700;
  color: #00479d !important;
  border-bottom: none !important;
  width: calc(100% - 22px);
}

::v-deep .el-menu--horizontal > .el-menu-item.is-active {
  font-weight: 700;
  color: #00479d !important;
  border-bottom: none !important;
}

::v-deep .el-menu--horizontal > .el-submenu.is-active .el-submenu__title {
  // border-top: 2px solid $themeColor;
  box-sizing: border-box;
}

::v-deep .submenu-parent {
  margin-right: 0.6rem;
  padding: 0;
  letter-spacing: 3px;
  font-size: 0.2rem;

  &:nth-last-child(1) {
    margin-right: 0;
  }
}

.submenu {
  background: rgba(255, 255, 255, 0.85) !important;
  letter-spacing: 2px;

  &:hover {
    color: #00479d;
    font-weight: normal !important;

    span {
      color: #00479d !important;
      font-weight: normal !important;
    }
  }
}

// ::v-deep .el-menu--popup {
//   // min-width: auto !important;
// }
</style>
<style>
.el-menu-demo .el-submenu__title:focus,
.el-submenu__title:hover {
  color: #00479d;
  outline: none;
  background: none !important;
}
</style>
